<template>
  <div class="page">
    <div class="mt-10 tc">
      <van-button size="small" icon="plus" @click="doEditAddress(null)">新增地址</van-button>
    </div>
    <div v-if="list.length">
      <van-cell-group v-for="(add, index) in list"
                      :key="index"
                      :title="add.addressname">
        <van-cell title="收货人"
                  :value="add.truename"></van-cell>
        <van-cell title="电话"
                  :value="add.phone"></van-cell>
        <van-cell title="详细地址"
                  :label="add.address"></van-cell>
        <van-cell>
          <div>
            <van-button size="small"
                        icon="delete" @click="doDelAddress(add.addressid)"></van-button>
            <span class="fr">
              <van-button size="small"
                          icon="flag-o"
                          v-if="add.isdefault!=1" @click="setDefAddress(add.addressid)">设为默认</van-button>
              <van-button size="small"
                          icon="flag-o"
                          v-else disabled>默认地址</van-button>
              <van-button size="small"
                          icon="edit"
                          type="info" @click="doEditAddress(add)">修改</van-button>
            </span>
          </div>
        </van-cell>
      </van-cell-group>
    </div>
    <div v-else>
      <van-empty description="您还未设置收货地址" />
    </div>

    <van-dialog v-model="modalVisible" :title="address.addressid>0?'修改地址': '新增地址'" show-cancel-button @confirm="doSaveAddress">
      <van-cell-group>
        <van-field label="地址名称" v-model="address.addressname" placeholder="起个名字 如：公司地址"></van-field>
        <van-field label="收货人" v-model="address.truename" placeholder="请填写收货人"></van-field>
        <van-field label="手机号" v-model="address.phone" placeholder="请填写有效手机号"></van-field>
        <van-field label="详细地址" v-model="address.address" placeholder="请具体到省市区街道门牌"></van-field>
        <van-button @click="doSaveAddress"></van-button>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup, Button, Field, Dialog, Empty } from 'vant'
Vue.use(Cell).use(CellGroup).use(Button).use(Field).use(Empty)
export default {
  name: 'Address',
  data () {
    return {
      list: [],
      modalVisible: false,
      address: {},
      page: 1,
      total: 0,
      size: 10
    }
  },
  methods: {
    getList () {
      this.$http.get('e/ShopSys/address/ListAddress.php').then(res => {
        if (res.code===0) {
          this.list = res.data.list;
        }
      })
    },
    doEditAddress(address){
      this.address = address || {};
      this.modalVisible = true;
    },
    doSaveAddress(){
      const action=this.address.addressid > 0 ?'EditAddress':'AddAddress';
      this.$http.post('e/ShopSys/?do='+action, this.address).then(res => {
        if(res.code===0) {
          this.modalVisible = false;
          this.getList();
        }
      })
    },
    setDefAddress(addressid){
      this.$http.get('e/ShopSys/?do=DefAddress', {addressid}).then(res => {
        if(res.code===0) {
          this.getList();
        }
      })
    },
    doDelAddress(addressid) {
      Dialog.confirm({message: '删除此地址？'}).then(()=>{
        this.$http.get('e/ShopSys/?do=DelAddress', {addressid}).then(res=> {
          if(res.code===0) {
            this.getList();
          }
        })
      })
    }
  },
  activated () {
    this.getList()
  },
}
</script>

<style>
</style>